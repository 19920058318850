/* eslint-disable @typescript-eslint/no-unsafe-call */
import { employeessNames } from '../../const';
import {SetStateAction, useState, useRef} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import {toast} from 'react-toastify';

import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchAddHolidaysAction } from '../../store/api-action';
import {getIsEmployeessLoaded} from '../../store/holidays-process/holidays-process';

import { TSubmitHolidays } from '../../types';
import { modals } from '../../utils/modals/init-modals';


export default function ModalNew(): JSX.Element {
  const isEmployeessLoaded = useAppSelector(getIsEmployeessLoaded);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, seteEndDate] = useState<Date | null>(null);
  const [family, seteFamily] = useState<string | null>(null);
  const [currentLi, setCurrentLi] = useState<string | null>(null);

  const liRef = useRef<HTMLLIElement | null>(null);
  const familyRef = useRef<HTMLSpanElement | null>(null);
  const selectRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();

  const resetForm = () => {
    setStartDate(null);
    seteEndDate(null);
    seteFamily(null);
    if (selectRef.current) {
      selectRef.current.className = 'custom-select';
    }

    const modal = document.querySelector('.modal-new');

    if (modal && currentLi) {
      const selectLi = modal.querySelector(`[data-select-value="${currentLi}"]`);
      if (selectLi) {
        selectLi.setAttribute('aria-selected','false');
      }
    }
  };

  const handleChangeFamily = (event: React.MouseEvent<HTMLLIElement>):void => {
    if (familyRef.current?.textContent) {
      seteFamily(String(familyRef.current?.textContent));
      const target = event.target as HTMLLIElement;
      setCurrentLi(target.getAttribute('data-select-value'));
    }
  };

  const handleChangeDate = (value: SetStateAction<Date | null>[]): void => {
    setStartDate(value[0]);
    seteEndDate(value[1]);
  };

  const onSubmit = (data: TSubmitHolidays) => {
    dispatch(fetchAddHolidaysAction(data));

    if (isEmployeessLoaded) {
      if (modals.modal) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const allModals: any = modals.modal;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        allModals.close(['modal-add-rest']);
      }
      resetForm();
      toast.success('🦄 Отпуск добавлен!', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (startDate && endDate && family) {
      onSubmit({
        daterestfrom: startDate.toISOString(),
        daterestto: endDate.toISOString(),
        family: family,
      });
    }
  };

  return (
    <div className="modal modal-new" data-modal="modal-add-rest">
      <div className="modal__wrapper">
        <div className="modal__overlay" data-close-modal>
        </div>
        <div className="modal__content">
          <div className="modal-new__wrapper-top">
            <div className="modal-new__text">
              <p>Добавить новый&nbsp;отпуск</p>
            </div>
            <div className="modal__close-btn" data-close-modal>
              <button className="btn">
                <svg width="35" height="35" aria-hidden="true">
                  <use xlinkHref="#close"></use>
                </svg>
              </button>
            </div>
          </div>
          <div className="modal-new__wrapper-bottom">
            <form action="#" method="post" onSubmit={handleSubmitForm}>
              <div ref={selectRef} className="custom-select" data-select data-insert="true"><span className="custom-select__placeholder"><span className="custom-select__placeholder-desktop">выбор сотрудника</span><span className="custom-select__placeholder-mobile">сотрудники</span></span>
                <button className="custom-select__button" type="button" aria-label="Выберите одну из опций">
                  <span ref={familyRef} className="custom-select__text">{family}</span>
                  <span className="custom-select__icon"></span>
                </button>
                <ul className="custom-select__list" role="listbox">
                  {employeessNames ? employeessNames.map((employeessName, index) =>
                    <li ref={liRef} onClick={handleChangeFamily} key={employeessName.family} className="custom-select__item custom-select__item--mod" tabIndex={0} data-select-value={index} aria-selected="false" role="option">{employeessName.family}</li>) : null}
                </ul>
              </div>
              <div className="custom-input visually-hidden" data-freeze-label="parent">
                <label htmlFor="employee">
                  <input type="text" id="employee" name="text" data-freeze-label="field"/>
                </label>
              </div>
              <div className="custom-input modal-new__input" data-freeze-label="parent">
                <label htmlFor="date">
                  <DatePicker
                    withPortal
                    isClearable
                    selectsRange
                    locale={ru}
                    dateFormat={'dd.MM.yyyy'}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleChangeDate}
                    placeholderText='выбрать дату'
                  />
                </label>
                {/* <input type="text" id="date" name="text" placeholder="выбрать дату" data-freeze-label="field"/> */}
              </div>
              <button className="btn btn--full-width" type="submit" disabled={!(startDate && endDate && family)}><span>Добавить</span></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
