import { Navigate } from 'react-router-dom';
import {useAppSelector} from '../../hooks/useAppSelector';
import {getAuthorizationStatus} from '../../store/user-process/user-process';

type PrivateRouteProps = {
  children: JSX.Element;
}

export default function PrivateRoute(props: PrivateRouteProps): JSX.Element {
  const {children} = props;
  const statusAuthorization = useAppSelector(getAuthorizationStatus);

  return (
    statusAuthorization ? children : <Navigate to='/main'/>
  );
}
