import { Helmet } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
import FormMain from '../../components/form-main/form-main';
import {useAppSelector} from '../../hooks/useAppSelector';
import {getAuthorizationStatus} from '../../store/user-process/user-process';
import {toast} from 'react-toastify';
import { useEffect } from 'react';

export default function Main(): JSX.Element {

  const statusAuthorization = useAppSelector(getAuthorizationStatus);

  useEffect(() => {
    if (statusAuthorization) {
      toast.success('Добавьте отпуск');
    }
  },[statusAuthorization]);

  return (
    !statusAuthorization ?
      <main>
        <Helmet>
          <title>URT</title>
        </Helmet>
        <section className="entrance">
          <h2 className="visually-hidden">вход в систему</h2>
          <div className="container">
            <div className="entrance__wrapper">
              <p className="entrance__title">введите пароль для&nbsp;входа в&nbsp;систему</p>
              <FormMain />
            </div>
          </div>
        </section>
      </main> : <Navigate to='/app' />
  );
}
