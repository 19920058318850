/* eslint-disable no-nested-ternary */
import ButtonDelEmployee from '../buttun-del-employee/buttun-del-employee';
import ButtonEditEmployee from '../button-edit-employee/button-edit-employee';
import { TEmployees } from '../../types';
import { employeessNames } from '../../const';
import { humanizeDate } from '../../utils/utils';
import { getDateDiff, getDateBetweenNow, getDateIsSame } from '../../utils/utils';


type RowTableProps = {
  employees: TEmployees;
}

export default function RowTable(props: RowTableProps): JSX.Element {
  const {employees} = props;
  const isDateBetweenNow = getDateBetweenNow(employees);
  const isDateSame = getDateIsSame(employees);
  const jobName = employeessNames ? employeessNames.find((employeessName) => employeessName.family === employees.family) : null;

  return (
    <tr>
      <td className={isDateBetweenNow || isDateSame ? 'active-holidays' : ''}>{employees.family}</td>
      <td>{jobName ? jobName.jobTitle : null}</td>
      <td>{humanizeDate(employees.daterestfrom)} - {humanizeDate(employees.daterestto)}</td>
      <td>{`${getDateDiff(employees)} ${(Number(getDateDiff(employees)) % 10 === 2 && Number(getDateDiff(employees)) !== 12) ||
         (Number(getDateDiff(employees)) % 10 === 3 && Number(getDateDiff(employees)) !== 13) ||
         (Number(getDateDiff(employees)) % 10 === 4 && Number(getDateDiff(employees)) !== 14) ? 'дня' : (Number(getDateDiff(employees)) % 10 === 1 && Number(getDateDiff(employees)) !== 11) ? 'день' : 'дней'}`}
      </td>
      <td>
        <ButtonEditEmployee id={employees._id}/>
      </td>
      <td>
        <ButtonDelEmployee id={employees._id} />
      </td>
    </tr>
  );
}
