import {combineReducers} from '@reduxjs/toolkit';
import {userProcess} from './user-process/user-process';
import {holidaysProcess} from './holidays-process/holidays-process';

const rootReducer = combineReducers({
  'USER': userProcess.reducer,
  'HOLIDAYS': holidaysProcess.reducer,
});

export default rootReducer;
