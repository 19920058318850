import {useAppSelector} from '../../hooks/useAppSelector';
import {getUser} from '../../store/user-process/user-process';

export default function StatusUser(): JSX.Element {
  const user = useAppSelector(getUser);

  return (
    <div className="header__box-users-text">
      <p>Статус пользователя:</p>
      <p className="header__users-status">{user?.fullName === 'admin' ? 'администратор' : 'сотрудник'}</p>
    </div>
  );
}
