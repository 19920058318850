import {TEmployees, TSubmitUser, TSubmitHolidays} from '../types';
import {TCurrentUser} from '../types/state.js';

import {AxiosInstance} from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {AppDispatch, State} from '../types/state.js';
import {saveToken} from '../services/token';
// import {redirectToRoute} from './action';

type ThunkApiConfig = {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}


export const fetchCheckAuthAction = createAsyncThunk<TCurrentUser, undefined, ThunkApiConfig>('app/сheckAuthAction',
  async (_arg, {extra: api}) => {
    const {data} = await api.get<TCurrentUser>('/me');
    return data;
  },
);

export const fetchLoginAction = createAsyncThunk<TCurrentUser, TSubmitUser, ThunkApiConfig>('app/loginAction',
  async ({fullName, password}, {dispatch, extra: api}) => {
    const {data} = await api.post<TCurrentUser>('/login', {fullName, password});
    data.token && saveToken(data.token);
    return data;
    // dispatch(redirectToRoute('/app'));
  },
);


export const fetchGetHolidaysAction = createAsyncThunk<TEmployees[], undefined, ThunkApiConfig>('app/getHolidaysAction',
  async (_arg, {extra: api}) => {
    const {data} = await api.get<TEmployees[]>('/posts');
    return data;
  },
);

export const fetchAddHolidaysAction = createAsyncThunk<TEmployees[], TSubmitHolidays, ThunkApiConfig>('app/addHolidaysAction',
  async ({daterestfrom, daterestto, family}, {extra: api}) => {
    const {data} = await api.post<TEmployees[]>('/posts', {daterestfrom, daterestto, family});
    return data;
  },
);

export const fetchDeleteHolidaysAction = createAsyncThunk<TEmployees[], string, ThunkApiConfig>('app/deleteHolidaysAction',
  async (id, {extra: api}) => {
    const {data} = await api.delete<TEmployees[]>(`/posts/${id}`);
    return data;
  },
);

export const fetchUpdateHolidaysAction = createAsyncThunk<TEmployees[], TEmployees, ThunkApiConfig>('app/updateHolidaysAction',
  async ({daterestfrom, daterestto, family, _id}, {extra: api}) => {
    const {data} = await api.put<TEmployees[]>(`/posts/${_id}`, {daterestfrom, daterestto, family, _id});
    return data;
  },
);

