import { Helmet } from 'react-helmet-async';
import {useEffect} from 'react';
// import { Navigate } from 'react-router-dom';

import FooterInfo from '../../components/footer-info/footer-info';
import EmployeeTable from '../../components/employee-table/employee-table';
import Control from '../../components/control/control';
import StatusUser from '../../components/status-user/status-user';
import HeaderBottom from '../../components/header-bottom/header-bottom';
import ModalNew from '../../components/modal-new/modal-new';
import ModalChange from '../../components/modal-change/modal-change';
import ModalDel from '../../components/modal-del/modal-del';

import {useAppSelector} from '../../hooks/useAppSelector';
import {iosVhFix, iosInputScaleFix} from '../../utils/ios-fix/ios-fix';
import {initModals} from '../../utils/modals/init-modals';
import {moveHeaderButton} from '../../utils/utils';
import {fetchCheckAuthAction} from '../../store/api-action';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {getUser} from '../../store/user-process/user-process';
import {getSortFamily, getEmployeess} from '../../store/holidays-process/holidays-process';

export default function AppPage(): JSX.Element {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const family = useAppSelector(getSortFamily);
  // const statusAuthorization = useAppSelector(getAuthorizationStatus);

  const employeess = useAppSelector(getEmployeess);
  const allFamily = employeess.map((employees) => employees.family);
  const uniqueFamily = new Set(allFamily);
  const amountOfemployeess = Number(Array.from(uniqueFamily).length);

  useEffect(() => {
    initModals();
    moveHeaderButton();
    iosVhFix();
    iosInputScaleFix();
    dispatch(fetchCheckAuthAction());
  },[dispatch]);


  return (
    <>
      <Helmet>
        <title>URT-app</title>
      </Helmet>
      <header className="header">
        <div className="header__wrapper">
          <div className="container">
            <div className="header__top-wrapper">
              <div className="header__box-users">
                <picture>
                  <source type="image/webp" srcSet={user ? `img/content/${user.avatarUrl}.webp, img/content/${user.avatarUrl}@2x.webp 2x` : 'img/content/lub.webp, img/content/lub@2x.webp 2x'} />
                  <img src={user ? `img/content/${user.avatarUrl}.jpg` : 'img/content/lub.jpg'} srcSet={user ? `img/content/${user.avatarUrl}@2x.jpg` : 'img/content/lub@2x.jpg 2x'} width="42" height="42" alt="фотография" />
                </picture>
                <StatusUser />
              </div>
            </div>
          </div>
          <div className="container">
            <HeaderBottom />
          </div>
        </div>
      </header>
      <main>
        <Control />
        <section className="rest">
          <div className="container">
            <h2>{`${family === '' ? 'Cписок отпусков сотрудников URT' : `Cписок отпусков (${family})` }`}</h2>
            <EmployeeTable />
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="footer__wrapper">
          <FooterInfo amount={amountOfemployeess}/>
        </div>
        <p className="footer__devepolicy">Разработано специально для Управления территориальной политики г. Люберцы.<span> 2023 г.</span></p>
      </footer>
      <ModalNew />
      <ModalChange />
      <ModalDel />
    </>
  );
}
