import {SetStateAction, useState, useEffect} from 'react';
import {useAppSelector} from '../../hooks/useAppSelector';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { TEmployees } from '../../types';
import {toast} from 'react-toastify';

import {modals} from '../../utils/modals/init-modals';
import {employeessNames} from '../../const';
import {fetchUpdateHolidaysAction} from '../../store/api-action';
import {getCurrentId, getEmployeess, getIsEmployeessLoaded} from '../../store/holidays-process/holidays-process';

export default function ModalChange(): JSX.Element {
  const isEmployeessLoaded = useAppSelector(getIsEmployeessLoaded);
  const currentId = useAppSelector(getCurrentId);
  const employeess = useAppSelector(getEmployeess);
  const currentEmployees = employeess.find((employees) => employees._id === currentId);
  const currentJobTitle = employeessNames.find((employeessName) => employeessName.family === currentEmployees?.family);

  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<Date | null >(null);
  const [endDate, seteEndDate] = useState<Date | null >(null);

  useEffect(() => {
    if (currentEmployees) {
      setStartDate(new Date (currentEmployees.daterestfrom));
      seteEndDate(new Date (currentEmployees.daterestto));
    }
  },[currentEmployees]);

  const handleChangeDate = (value: SetStateAction<Date | null>[]): void => {
    setStartDate(value[0]);
    seteEndDate(value[1]);
  };

  const onSubmit = async (data: TEmployees) => {
    await dispatch(fetchUpdateHolidaysAction(data));
    if (isEmployeessLoaded) {
      if (modals.modal) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const allModals: any = modals.modal;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        allModals.close(['modal-change-rest']);
      }
      toast.info('🦄 Даты обновлены!', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      // resetForm();
    }
  };

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const dateRestFrom = startDate ? startDate.toISOString() : new Date().toISOString();
    const dateRestTo = endDate ? endDate.toISOString() : new Date().toISOString();
    onSubmit({
      _id: currentId,
      family: currentEmployees ? currentEmployees.family : '',
      daterestfrom: dateRestFrom,
      daterestto: dateRestTo,
    });
  };

  return (
    <div className="modal modal-new " data-modal="modal-change-rest">
      <div className="modal__wrapper">
        <div className="modal__overlay" data-close-modal>
        </div>
        <div className="modal__content">
          <div className="modal-new__wrapper-top">
            <div className="modal-new__text">
              <p>Изменение даты&nbsp;отпуска</p>
            </div>
            <div className="modal__close-btn" data-close-modal>
              <button className="btn">
                <svg width="35" height="35" aria-hidden="true">
                  <use xlinkHref="#close"></use>
                </svg>
              </button>
            </div>
          </div>
          <div className="modal-new__wrapper-bottom">
            <div className="modal-new__text-box">
              <p className="modal-new__text-first">{currentEmployees?.family}</p>
              <p className="modal-new__text-second">{currentJobTitle?.jobTitle}</p>
            </div>

            <form action="#" method="post" onSubmit={handleSubmitForm}>
              <div className="custom-input modal-new__input" data-freeze-label="parent">
                <label htmlFor="date-add">
                  <DatePicker
                    popperModifiers={[
                      {
                        name: 'offset',
                        options: {
                          offset: [90, 0],
                        },
                      },
                      {
                        name: 'preventOverflow',
                        options: {
                          rootBoundary: 'viewport',
                          tether: false,
                          altAxis: true,
                        },
                      },
                    ]}
                    isClearable
                    selectsRange
                    locale={ru}
                    dateFormat={'dd.MM.yyyy'}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleChangeDate}
                    placeholderText='выбрать дату'
                  />
                </label>
              </div>
              <button className="btn btn--full-width" type="submit" disabled={!(startDate && endDate)}><span>Изменить</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
