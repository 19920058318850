import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {State} from '../../types/state';
import {TEmployees} from '../../types';
import {fetchGetHolidaysAction, fetchAddHolidaysAction, fetchDeleteHolidaysAction, fetchUpdateHolidaysAction} from '../api-action';

type TState = {
  currentId: string;
  sortFamily: string;
  sortMonth: number;
  employeess: TEmployees[];
  isEmployeessLoaded: boolean;
};

const initialState: TState = {
  currentId: '',
  sortFamily: '',
  sortMonth: 0,
  employeess: [],
  isEmployeessLoaded: false,
};


export const holidaysProcess = createSlice({
  name: 'HOLIDAYS',
  initialState,
  reducers: {
    changeCurrentId: (state, action: PayloadAction<string>) => {
      state.currentId = action.payload;
    },
    changeFamily: (state, action: PayloadAction<string>) => {
      state.sortFamily = action.payload;
    },
    changeMonth: (state, action: PayloadAction<number>) => {
      state.sortMonth = action.payload;
    },
    loadHolidays: (state, action: PayloadAction<TEmployees[]>) => {
      state.employeess = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGetHolidaysAction.pending, (state) => {
        state.employeess = [];
        state.isEmployeessLoaded = false;
      })
      .addCase(fetchGetHolidaysAction.rejected, (state) => {
        state.employeess = [];
        state.isEmployeessLoaded = false;
      })
      .addCase(fetchGetHolidaysAction.fulfilled, (state, action) => {
        state.employeess = action.payload;
        state.isEmployeessLoaded = true;
      })

      .addCase(fetchAddHolidaysAction.pending, (state) => {
        state.employeess = [];
        state.isEmployeessLoaded = false;
      })
      .addCase(fetchAddHolidaysAction.rejected, (state) => {
        state.employeess = [];
        state.isEmployeessLoaded = false;
      })
      .addCase(fetchAddHolidaysAction.fulfilled, (state, action) => {
        state.employeess = action.payload;
        state.isEmployeessLoaded = true;
      })

      .addCase(fetchDeleteHolidaysAction.pending, (state) => {
        state.employeess = [];
        state.isEmployeessLoaded = false;
      })
      .addCase(fetchDeleteHolidaysAction.rejected, (state) => {
        state.employeess = [];
        state.isEmployeessLoaded = false;
      })
      .addCase(fetchDeleteHolidaysAction.fulfilled, (state, action) => {
        state.employeess = action.payload;
        state.isEmployeessLoaded = true;
        state.sortFamily = '';
      })

      .addCase(fetchUpdateHolidaysAction.pending, (state) => {
        state.employeess = [];
        state.isEmployeessLoaded = false;
      })
      .addCase(fetchUpdateHolidaysAction.rejected, (state) => {
        state.employeess = [];
        state.isEmployeessLoaded = false;
      })
      .addCase(fetchUpdateHolidaysAction.fulfilled, (state, action) => {
        state.employeess = action.payload;
        state.isEmployeessLoaded = true;
        state.sortFamily = '';
      });
  }
});

export const {changeCurrentId, changeFamily, changeMonth, loadHolidays} = holidaysProcess.actions;

export const getIsEmployeessLoaded = (state: State): boolean => state['HOLIDAYS'].isEmployeessLoaded;
export const getEmployeess = (state: State): TEmployees[] => state['HOLIDAYS'].employeess;
export const getSortFamily = (state: State): string => state['HOLIDAYS'].sortFamily;
export const getCurrentId = (state: State): string => state['HOLIDAYS'].currentId;
export const getSortMonth = (state: State): number => state['HOLIDAYS'].sortMonth;
