const iosChecker = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

const iosVhFix = () => {
  if (!(!!window.MSInputMethodContext && !!document.documentMode)) {
    if (iosChecker()) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      window.addEventListener('resize', () => {
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
  }
};

const iosInputScaleFix = () => {
  if (iosChecker()) {
    const vp = document.querySelector('meta[name="viewport"]');
    let content = vp.getAttribute('content');
    if (content) {
      content = `${content}, maximum-scale=1.0`;
      vp.setAttribute('content', content);
    }
  }
};

export {iosVhFix, iosInputScaleFix};
