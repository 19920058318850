import axios, {AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse} from 'axios';
import {getToken} from './token';
import {BACKEND_URL, REQUEST_TIMEOUT} from '../const';
import {toast} from 'react-toastify';


const StatusCodeMapping: Record<string, boolean> = {
  '400': true,
  '403': true,
  '404': true,
};

const shouldDisplayError = (response: AxiosResponse) => StatusCodeMapping[response.status];

export const createAPI = (): AxiosInstance => {
  const api = axios.create({
    baseURL: BACKEND_URL,
    timeout: REQUEST_TIMEOUT,
  });

  api.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = getToken();

      if (token && config.headers) {
        // config.headers['x-token'] = token;
        config.headers.authorization = token;
      }

      return config;
    },
  );

  api.interceptors.response.use((response: AxiosResponse) => response, (error: AxiosError<{error: string; statusText: string; message?: string}>) => {
    if (error.response && shouldDisplayError(error.response)) {

      if (error.response.statusText === 'Bad Request' && error.code === 'ERR_BAD_REQUEST') {
        toast.error(error.response.data.message);
        return;
      }
    }

    throw error;
  });

  return api;
};
