import {Route, Routes} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';

import Main from '../../pages/main/main';
import AppPage from '../../pages/app-page/app-page';
import NotFound from '../../pages/not-found/not-found';
import PrivateRoute from '../private-route/private-route';


import HistoryRouter from '../history-router/history-router';
import browserHistory from '../../browser-history';

function App(): JSX.Element {

  return (
    <HelmetProvider>
      <HistoryRouter history={browserHistory}>
        <Routes>
          <Route path={'/'} element=
            {
              <PrivateRoute>
                <AppPage />
              </PrivateRoute>
            }
          />
          {/* <Route index element={<Main />} /> */}
          <Route path={'/main'} element={<Main />} />
          <Route path={'/app'} element={<AppPage />} />
          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </HistoryRouter>
    </HelmetProvider>
  );
}

export default App;
