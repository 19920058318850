import {useAppSelector} from '../../hooks/useAppSelector';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {fetchDeleteHolidaysAction} from '../../store/api-action';
import {humanizeDate} from '../../utils/utils';
import {toast} from 'react-toastify';
import {getCurrentId, getEmployeess, getIsEmployeessLoaded} from '../../store/holidays-process/holidays-process';


export default function ModalDel(): JSX.Element {
  const isEmployeessLoaded = useAppSelector(getIsEmployeessLoaded);
  const currentId = useAppSelector(getCurrentId);
  const employeess = useAppSelector(getEmployeess);
  const currentEmployees = employeess.find((employees) => employees._id === currentId);

  const dispatch = useAppDispatch();

  const handleClickButtonDelete = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(fetchDeleteHolidaysAction(currentId));
    if (isEmployeessLoaded) {
      toast.error('Отпуск удален', {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        // #e86969
      });
    }
  };

  return (
    <div className="modal modal-new modal-new--del" data-modal="modal-delete-rest">
      <div className="modal__wrapper">
        <div className="modal__overlay" data-close-modal>
          <svg className="modal-new__sign-svg" width="56" height="56" aria-hidden="true">
            <use xlinkHref="#sign"></use>
          </svg>
        </div>
        <div className="modal__content">
          <div className="modal-new__wrapper-bottom">
            <div className="modal-new__text-box">
              <p className="modal-new__text-first">Удалить отпуск?</p>
              <p className="modal-new__text-second">c {currentEmployees && humanizeDate(currentEmployees.daterestfrom)} по {currentEmployees && humanizeDate(currentEmployees.daterestto)}</p>
            </div>
            <div className="modal-new__button-wrapper">
              <button onClick={handleClickButtonDelete} className="btn btn--full-width" type="button" data-close-modal><span>Да</span>
              </button>
              <button className="btn btn--gray" type="button" data-close-modal><span>Нет</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
