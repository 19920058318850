import { memo } from 'react';

type footerInfoProps = {
  amount: number;
}

function FooterInfo(props: footerInfoProps): JSX.Element {
  return (
    <div className="footer__info container">
      <p>
        Количество зарегистрированных<br/> сотрудников:<span> {props.amount}</span>
      </p>
    </div>
  );
}

export default memo(FooterInfo, (prev, next) => prev.amount === next.amount);
