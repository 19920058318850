import {useAppSelector} from '../../hooks/useAppSelector';
import {getAuthorizationStatus} from '../../store/user-process/user-process';

export default function HeaderBottom(): JSX.Element {
  const statusAuthorization = useAppSelector(getAuthorizationStatus);

  return (
    <div className="header__bottom-wrapper">
      <div className="header__box-img">
        <picture>
          <source type="image/webp" srcSet="img/content/lub.webp, img/content/lub@2x.webp 2x" />
          <img src="img/content/lub.jpg" srcSet="img/content/lub@2x.jpg 2x" width="125" height="125" alt="фотография" />
        </picture>
      </div>
      <div className="header__box-text">
        <p>г.<span> Люберцы</span></p>
        <h1>управление территориальной политики</h1>
        <p>график отпусков</p>
      </div>

      <button disabled={!statusAuthorization} className="btn header__bottom-wrapper-button" type="button" data-open-modal="modal-add-rest">
        <span>добавить отпуск</span>
      </button>
    </div>
  );
}
