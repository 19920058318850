import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TUserProcess, TCurrentUser, State} from '../../types/state';
import { fetchCheckAuthAction, fetchLoginAction } from '../api-action';
import {dropToken} from '../../services/token';

const initialState: TUserProcess = {
  authorizationStatus: false,
  user: null,
};

export const userProcess = createSlice({
  name: 'USER',
  initialState,
  reducers: {
    outAuthorization: (state, action: PayloadAction<boolean>) => {
      state.authorizationStatus = Boolean(action.payload);
      state.user = null;
      dropToken();
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(fetchCheckAuthAction.pending, (state) => {
      //   state.authorizationStatus = false;
      //   state.user = null;
      // })
      .addCase(fetchCheckAuthAction.rejected, (state) => {
        state.authorizationStatus = false;
        state.user = null;
      })
      .addCase(fetchCheckAuthAction.fulfilled, (state, action) => {
        state.authorizationStatus = true;
        state.user = action.payload;
      })

      .addCase(fetchLoginAction.pending, (state) => {
        state.authorizationStatus = false;
        state.user = null;
      })
      .addCase(fetchLoginAction.rejected, (state) => {
        state.authorizationStatus = false;
        state.user = null;
      })
      .addCase(fetchLoginAction.fulfilled, (state, action) => {
        state.authorizationStatus = true;
        state.user = action.payload;
      });
  }
});

export const {outAuthorization} = userProcess.actions;

export const getAuthorizationStatus = (state: State): boolean => state['USER'].authorizationStatus;
export const getUser = (state: State): TCurrentUser | null => state['USER'].user;
