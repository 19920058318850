import {Modals} from './modals';

const modals = {
  modal: undefined
};

// let close = (modal) => {
//   close
// };
// Здесь реализован пример открытия модалки через колбэк закрытия

// const openModalInCloseCallback = (name, context = this) => {
//   context._enableScrolling = false;
//   context._setSettings('default');
//   modals.open(name);
// };

// closeCallback() {
//   openModalInCloseCallback('modal-add-rest');
// }

export const settings = {
  'default': {
    preventDefault: true,
    stopPlay: true,
    lockFocus: true,
    startFocus: true,
    focusBack: true,
    eventTimeout: 400,
    openCallback: false,
    closeCallback: false,
  },
};

const initModals = () => {
  // const modalElements = document.querySelectorAll('.modal');
  // if (modalElements.length) {
  //   modalElements.forEach((el) => {
  //     setTimeout(() => {
  //       el.classList.remove('modal--preload');
  //     }, 100);
  //   });
  // }

  modals.modal = new Modals(settings);

  // Используйте в разработке экспортируемую переменную modals, window сделан для бэкэнда
  // window.modals = modals;

};


export {modals, initModals};
