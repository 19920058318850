import RowTable from '../row-table/row-table';
import Loading from '../../components/loading/loading';

import {sortByName, filterByName, filterByMonth} from '../../utils/utils';

import {useEffect} from 'react';
import {useAppSelector} from '../../hooks/useAppSelector';
import {useAppDispatch} from '../../hooks/useAppDispatch';

import {fetchGetHolidaysAction} from '../../store/api-action';
import {getSortFamily, getEmployeess, getSortMonth, getIsEmployeessLoaded} from '../../store/holidays-process/holidays-process';
import {getAuthorizationStatus} from '../../store/user-process/user-process';

export default function EmployeeTable(): JSX.Element {
  const isEmployeessLoaded = useAppSelector(getIsEmployeessLoaded);
  const statusAuthorization = useAppSelector(getAuthorizationStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchGetHolidaysAction());
  },[dispatch]);

  const employeess = useAppSelector(getEmployeess);
  const sortFamily = useAppSelector(getSortFamily);
  const sortMonth = useAppSelector(getSortMonth);
  const sortedEmployeess = employeess.map((allEmployeess) => allEmployeess).sort(sortByName);
  const filteredByNameEmployeess = filterByName(sortedEmployeess, sortFamily);
  const filteredByMonth = filterByMonth(filteredByNameEmployeess, sortMonth);
  return (
    isEmployeessLoaded && statusAuthorization ?
      <table className="table">
        <tbody>
          <tr>
            <th>ФИО</th>
            <th>Должность</th>
            <th>Дата</th>
            <th>Продолжительность</th>
            <th colSpan={2}>Редактирование</th>
          </tr>
          {filteredByMonth.map((employees) => <RowTable employees={employees} key={employees._id} />)}
        </tbody>
      </table> : <Loading />
  );
}
