import {useAppDispatch} from '../../hooks/useAppDispatch';
import {useAppSelector} from '../../hooks/useAppSelector';
import {changeCurrentId} from '../../store/holidays-process/holidays-process';
import {getUser} from '../../store/user-process/user-process';
import { useCallback } from 'react';

type ButtonEditEmployeeProps = {
  id: string;
}

export default function ButtonEditEmployee(props: ButtonEditEmployeeProps): JSX.Element {
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();
  const {id} = props;

  const handleClickButtonEdit = useCallback(
    (event: React.FormEvent<HTMLButtonElement>) => {
      event.preventDefault();
      dispatch(changeCurrentId(id));
    }, [id, dispatch]
  );

  return (
    <button onClick={handleClickButtonEdit} disabled={user?.fullName === 'user'} data-id={id} className="btn" type="button" aria-label="редактировать строку" data-open-modal="modal-change-rest">
      <svg width="32" height="32" aria-hidden="true">
        <use xlinkHref="#edit"></use>
      </svg>
    </button>
  );
}
