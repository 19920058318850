import {useAppDispatch} from '../../hooks/useAppDispatch';
import {changeMonth} from '../../store/holidays-process/holidays-process';

type SortDateProps = {
  months: string[];
}

export default function SortDate({months}:SortDateProps): JSX.Element {
  const dispatch = useAppDispatch();

  const handleClickMonth = (event: React.UIEvent<HTMLLIElement>) => {
    event.preventDefault();
    dispatch(changeMonth(Number(event.currentTarget.dataset.selectValue)));
  };

  return (
    <div className="custom-select control__select" data-select data-insert="true"><span className="custom-select__placeholder"><span className="custom-select__placeholder-desktop">сортировка по месяцу</span><span className="custom-select__placeholder-mobile">месяц</span></span>
      <button className="custom-select__button" type="button" aria-label="Выберите одну из опций"><span className="custom-select__text"></span><span className="custom-select__icon"></span></button>
      <ul className="custom-select__list" role="listbox">
        {months.map((month) => month).map((oneMonth, index) => <li onClick={handleClickMonth} key={oneMonth} className="custom-select__item custom-select__item--mod" tabIndex={0} data-select-value={index + 1} aria-selected="false" role="option">{oneMonth}</li>)}
      </ul>
    </div>
  );
}
