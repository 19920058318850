import {Link} from 'react-router-dom';
import {useEffect} from 'react';

import SortEmployee from '../sort-employee/sort-employee';
import SortDate from '../sort-date/sort-date';
import SortReset from '../sort-reset/sort-reset';

import {getDateNow} from '../../utils/utils';
import {CustomSelect} from '../../utils/select/custom-select';

import {useAppDispatch} from '../../hooks/useAppDispatch';
import {outAuthorization} from '../../store/user-process/user-process';
import {changeFamily, changeMonth} from '../../store/holidays-process/holidays-process';


import { months } from '../../const';

export default function Control(): JSX.Element {

  const dispatch = useAppDispatch();

  useEffect(() => {
    const cSelect = new CustomSelect();
    cSelect.init();
  },);

  const handleOutAuthorization = () => {
    dispatch(outAuthorization(false));
    dispatch(changeFamily(''));
    dispatch(changeMonth(0));
  };

  return (
    <section className="control">
      <h2 className="visually-hidden">панель управления</h2>
      <div className="control__wrapper">
        <Link onClick={handleOutAuthorization} className="btn control__btn control__btn--out" aria-label="Выход из системы" to="/">
          <span>
            выход
            <span className="control__btn-text-no-mobile"> из системы</span>
          </span>
        </Link>
        <button className="btn control__btn control__btn--print" type="button" aria-label="Напечатать список" onClick={() => window.print()}>
          <svg width="35" height="35" aria-hidden="true">
            <use xlinkHref="#print"></use>
          </svg>
        </button>
        <div className="box-current-date control__date">
          <p><span>сегодня:&nbsp;</span><span className="control__date-now">{getDateNow()}</span></p>
        </div>
        <div className="control__box-sort">
          <SortEmployee />
          <SortDate months={months} />
          <SortReset />
        </div>
      </div>
    </section>
  );
}
