
import {createElement, renderElement} from './render';
import {createNativeSelectMarkup} from './create-select-markup';

export class CustomSelect {
  constructor() {
    this._selects = null;
    this._selectElement = null;
    this._activeIndex = null;

    this._onDocumentClick = this._onDocumentClick.bind(this);
    this._onEscapePress = this._onEscapePress.bind(this);
    this._onSelectItemClick = this._onSelectItemClick.bind(this);
    this._onSelectItemKeydown = this._onSelectItemKeydown.bind(this);
    this._onLastItemKeydown = this._onLastItemKeydown.bind(this);
    this._onSelectClick = this._onSelectClick.bind(this);
    this._onSelectKeydown = this._onSelectKeydown.bind(this);

    window.selectInit = this.init.bind(this);
  }

  _createMultiString(arr) {
    let str = '';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (arr.length) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (arr.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        str = arr[0].innerHTML;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        arr.forEach((element, index) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          if (index === arr.length - 1) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            str += element.innerHTML;
          } else {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
            str += `${element.innerHTML}, `;
          }
        });
      }
    }
    return str;
  }

  _setSelectActiveState(multiple, insert, item) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const buttonTextBlock = item.querySelector('.custom-select__text');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const activeItems = item.querySelectorAll('.custom-select__item[aria-selected="true"]');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const label = item.querySelector('.custom-select__label');
    const str = this._createMultiString(activeItems);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    buttonTextBlock.style.transition = '0.2s';
    if (label) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      label.style.transition = '0.2s';
    }

    setTimeout(() => {
      if (label) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        label.style.transition = null;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      buttonTextBlock.style.transition = null;
    }, 300);

    if (multiple && insert) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      item.classList.add('not-empty');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      buttonTextBlock.innerHTML = str;
    } else if (multiple) {
      // eslint-disable-next-line no-useless-return
      return;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      item.classList.add('not-empty');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      buttonTextBlock.innerHTML = activeItems[0].innerHTML;
    }
  }

  _closeSelect() {
    const activeSelect = document.querySelector('[data-select].is-open');
    // eslint-disable-next-line @typescript-eslint/unbound-method
    document.removeEventListener('click', this._onDocumentClick);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    document.removeEventListener('keydown', this._onEscapePress);
    if (activeSelect) {
      activeSelect.classList.remove('is-open');
    }
  }

  _onSelectElementClickAction(element, index) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const parent = element.closest('.custom-select');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const multiple = parent.dataset.multiple;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const insert = parent.dataset.insert;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const buttonTextBlock = parent.querySelector('.custom-select__text');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const itemText = element.textContent;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const options = parent.querySelectorAll('option');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const select = parent.querySelector('select');
    const changeEv = new CustomEvent('change');
    const inputEv = new CustomEvent('input');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    select.dispatchEvent(changeEv);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    select.dispatchEvent(inputEv);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const form = select.closest('form');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const parentMessage = parent.querySelector('.input-message');
    if (parentMessage) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      parentMessage.remove();
    }

    if (form) {
      const formChangeEv = new CustomEvent('change');
      const formInputEv = new CustomEvent('input');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      form.dispatchEvent(formChangeEv);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      form.dispatchEvent(formInputEv);
    }

    if (multiple) {
      if (insert === 'true') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (element.getAttribute('aria-selected') === 'true') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          element.setAttribute('aria-selected', 'false');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          const activeItems = parent.querySelectorAll('.custom-select__item[aria-selected="true"]');
          const str = this._createMultiString(activeItems);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-plus-operands
          options[index + 1].selected = false;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          buttonTextBlock.innerText = str;
          if (!str) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            parent.classList.remove('not-empty');
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
            parent.classList.remove('is-valid');
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          element.setAttribute('aria-selected', 'true');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          const activeItems = parent.querySelectorAll('.custom-select__item[aria-selected="true"]');
          const str = this._createMultiString(activeItems);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          buttonTextBlock.innerText = str;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          parent.classList.add('not-empty');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          parent.classList.add('is-valid');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-plus-operands
          options[index + 1].selected = true;
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (element.getAttribute('aria-selected') === 'true') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          element.setAttribute('aria-selected', 'false');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-plus-operands
          options[index + 1].selected = false;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          element.setAttribute('aria-selected', 'true');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-plus-operands
          options[index + 1].selected = true;
        }
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const activeItem = parent.querySelector('.custom-select__item[aria-selected="true"]');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (element.getAttribute('aria-selected') === 'true') {
        this._closeSelect();
      } else {
        if (activeItem) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          activeItem.setAttribute('aria-selected', 'false');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          parent.classList.remove('not-empty');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          parent.classList.remove('is-valid');
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        buttonTextBlock.innerText = itemText;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        element.setAttribute('aria-selected', 'true');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        parent.classList.add('not-empty');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        parent.classList.add('is-valid');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-plus-operands
        options[index + 1].selected = true;
        this._closeSelect();
      }
    }
  }

  _onDocumentClick({target}) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    if (!target.closest('.custom-select')) {
      this._closeSelect();
    }
  }

  _onEscapePress(evt) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const isEscape = evt.key === 'Escape';
    if (isEscape) {
      this._closeSelect();
    }
  }

  _onSelectItemClick(element, index) {
    this._onSelectElementClickAction(element, index);
  }

  _onSelectItemKeydown(evt, element, index) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const isEnter = evt.key === 'Enter';
    if (isEnter) {
      this._onSelectElementClickAction(element, index);
    }
  }

  _onLastItemKeydown(evt) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const isTab = evt.key === 'Tab';
    if (isTab) {
      this._closeSelect();
    }
  }

  _onSelectClick(evt) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const parent = evt.target.closest('[data-select]');
    const activeSelect = document.querySelector('[data-select].is-open');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    parent.classList.remove('is-invalid');

    if (activeSelect && activeSelect === parent) {
      activeSelect.classList.remove('is-open');
      return;
    }

    if (activeSelect) {
      this._closeSelect();
    }

    // eslint-disable-next-line @typescript-eslint/unbound-method
    document.addEventListener('click', this._onDocumentClick);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    document.addEventListener('keydown', this._onEscapePress);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    if (parent.classList.contains('is-open')) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      parent.classList.remove('is-open');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      parent.classList.add('is-open');
    }
  }

  _onSelectKeydown(evt) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const parent = evt.target.closest('[data-select]');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    parent.classList.remove('is-invalid');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    if (evt.shiftKey && evt.key === 'Tab' && parent.closest('.is-open')) {
      this._closeSelect();
    }
  }

  _setActiveSelectItemsState(multiple, selectItems) {
    let flag = true;
    this._activeIndex = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    selectItems.forEach((item, index) => {
      if (multiple) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (item.getAttribute('aria-selected') === 'true') {
          this._activeIndex.push(index);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (item.getAttribute('aria-selected') === 'true' && flag) {
          this._activeIndex.push(index);
          flag = false;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          item.setAttribute('aria-selected', 'false');
        }
      }
    });
  }

  _createSelectStructure(item) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const nativeSelect = item.querySelector('select');
    if (nativeSelect) {
      this._selectElement = null;
      return;
    }
    const options = {};
    options.items = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    const multiple = item.dataset.multiple;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const id = item.dataset.id;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const name = item.dataset.name;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const required = item.dataset.required;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const insert = item.dataset.insert;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const selectItems = item.querySelectorAll('.custom-select__item');
    this._setActiveSelectItemsState(multiple, selectItems);

    if (this._activeIndex.length) {
      options.activeIndex = this._activeIndex;
      this._setSelectActiveState(multiple, insert, item);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    options.name = name || false;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    options.id = id || false;
    options.required = Boolean(required);
    options.multiple = Boolean(multiple);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    selectItems.forEach((selectItem) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const value = selectItem.dataset.selectValue;
      const itemInfo = {};
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      itemInfo.text = selectItem.textContent;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      itemInfo.value = value;
      options.items.push(itemInfo);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    renderElement(item, createElement(createNativeSelectMarkup(options)));
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    this._selectElement = item;
    this._activeIndex = null;
  }

  _setSelectAction() {
    if (!this._selectElement) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const button = this._selectElement.querySelector('.custom-select__button');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const selectItems = this._selectElement.querySelectorAll('.custom-select__item');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/unbound-method
    button.addEventListener('click', this._onSelectClick);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/unbound-method
    button.addEventListener('keydown', this._onSelectKeydown);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    selectItems.forEach((element, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      element.addEventListener('click', () => {
        this._onSelectItemClick(element, index);
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      element.addEventListener('keydown', (evt) => {
        this._onSelectItemKeydown(evt, element, index);
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      if (index === selectItems.length - 1) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/unbound-method
        element.addEventListener('keydown', this._onLastItemKeydown);
      }
    });
  }

  init() {
    this._selects = document.querySelectorAll('[data-select]');
    this._selects.forEach((select) => {
      this._createSelectStructure(select);
      this._setSelectAction();
    });
  }
}
