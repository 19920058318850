import dayjs from 'dayjs';
import Dayjs from 'dayjs';
import { TEmployees } from '../types';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(duration);
dayjs.extend(isBetween);


export const reset = () => {
  const button = document.querySelector('.control__btn--reset');
  const selects = document.querySelectorAll('.control__select');
  const selectsLi = document.querySelectorAll('.custom-select__item');

  const resetSelects = () => {
    selects.forEach((select) => {
      if (select.classList.contains('is-valid') || select.classList.contains('is-valid')) {
        select.classList.remove('not-empty');
        select.classList.remove('is-valid');
      }
    });

    selectsLi.forEach((li) => {
      li.setAttribute('aria-selected','false');
    });
  };

  if (button && selects && selectsLi) {
    button.addEventListener('click', resetSelects);
  }

};

export const moveHeaderButton = () => {
  const buttonHeader = document.querySelector('.header__bottom-wrapper-button');
  const headerWrapper = document.querySelector('.header__bottom-wrapper');
  const controlDate = document.querySelector('.control__date');

  const breakpoint = window.matchMedia('(max-width:500px)');
  const breakpointChecker = () => {
    if (breakpoint.matches && buttonHeader && controlDate) {
      buttonHeader.remove();
      controlDate.after(buttonHeader);
    } else {
      if (headerWrapper && buttonHeader) {
        headerWrapper.append(buttonHeader);
      }
    }
  };

  breakpoint.addListener(breakpointChecker);

  if (headerWrapper && buttonHeader) {
    breakpointChecker();
  }
};

export const getDateBetweenNow = (employees:TEmployees): boolean => dayjs().isBetween(employees.daterestfrom, employees.daterestto);
export const getDateDiff = (employees:TEmployees) => Number(dayjs(employees.daterestto).diff(employees.daterestfrom, 'day')) + 1;
export const getDateIsSame = (employees:TEmployees) => dayjs().isSame(employees.daterestto, 'day') || dayjs().isSame(employees.daterestfrom, 'day');
export const sortByMonth = (employeess:TEmployees[]) => employeess.filter((employees) => dayjs().isSame(employees.daterestfrom, 'month'));
export const sortByName = (employeesA:TEmployees, employeesB:TEmployees) => employeesA.family.valueOf() > employeesB.family.valueOf() ? 1 : -1;
export const filterByName = (employeess:TEmployees[], family: string) => employeess.filter((employees) => {
  if(family === '') {
    return employeess;
  }
  return employees.family === family;
});

export const filterByMonth = (employeess:TEmployees[], month: number) => employeess.filter((employees) => {
  if(month === 0) {
    return employeess;
  }
  const currentMonth = month < 9 ? `0${month}` : month;
  return dayjs(employees.daterestfrom).isSame(`2023-${currentMonth}-01`, 'month');
});

// const getRandomInteger = (a = 0, b = 1) => {
//   const lower = Math.ceil(Math.min(a, b));
//   const upper = Math.floor(Math.max(a, b));

//   return Math.floor(lower + Math.random() * (upper - lower + 1));
// };

// export const getRandomCity = () => {
//   const CITIES_NAMES = ['Paris', 'Cologne', 'Brussels', 'Amsterdam', 'Hamburg', 'Dusseldorf'];
//   const randomIndex = getRandomInteger(0, CITIES_NAMES.length - 1);
//   return CITIES_NAMES[randomIndex];
// };

export const humanizeDate = (date: string): string => dayjs(date).format('DD.MM.YYYY');
export const getDateNow = (date?: keyof Dayjs.ConfigType): string => dayjs(date).format('DD.MM.YYYY');
