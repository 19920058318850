import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/app';
import {Provider} from 'react-redux';
import {store} from './store';

import {ToastContainer} from 'react-toastify';
import {fetchCheckAuthAction} from './store/api-action';

store.dispatch(fetchCheckAuthAction);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store = {store}>
    <ToastContainer
      position='top-center'
      theme='colored'
      autoClose={1500}
    />
    <App />
  </Provider>
);
