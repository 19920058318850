import { reset } from '../../utils/utils';
import { changeMonth, changeFamily } from '../../store/holidays-process/holidays-process';
import { useAppDispatch } from '../../hooks/useAppDispatch';

export default function SortReset(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleClickReset = (): void => {
    reset();
    dispatch(changeFamily(''));
    dispatch(changeMonth(0));
  };

  return (
    <button onClick={handleClickReset} className="btn control__btn control__btn--reset" type="button">
      <svg width="24" height="31" aria-hidden="true">
        <use xlinkHref="#reset"></use>
      </svg>
    </button>
  );
}
