import {useAppDispatch} from '../../hooks/useAppDispatch';
import {useAppSelector} from '../../hooks/useAppSelector';
import {changeCurrentId} from '../../store/holidays-process/holidays-process';
import {getUser} from '../../store/user-process/user-process';

type ButtonDelEmployeeProps = {
  id: string;
}

export default function ButtonDelEmployee(props: ButtonDelEmployeeProps): JSX.Element {
  const dispatch = useAppDispatch();
  const {id} = props;
  const user = useAppSelector(getUser);

  const handleClickButtonDelete = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(changeCurrentId(id));
  };

  return (
    <button onClick={handleClickButtonDelete} className="btn" disabled={user?.fullName !== 'admin'} type="button" aria-label="удалить строку" data-open-modal="modal-delete-rest">
      <svg width="32" height="32" aria-hidden="true">
        <use xlinkHref="#close"></use>
      </svg>
    </button>
  );
}
