import {useState, useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { TSubmitUser } from '../../types';

import {useAppDispatch} from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { fetchLoginAction, fetchCheckAuthAction } from '../../store/api-action';
import {getAuthorizationStatus} from '../../store/user-process/user-process';

export default function FormMain(): JSX.Element {
  const [receivedName, setReceivedName] = useState('');
  const [receivedPassword, setReceivedPassword] = useState('');
  const [isEmptyField, setEmptyField] = useState(false);
  const [isAuthorized, setAuthorized] = useState<boolean>(false);
  const statusAuthorization = useAppSelector(getAuthorizationStatus);

  const dispatch = useAppDispatch();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
    setReceivedName(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
    setReceivedPassword(String(event.target.value));
  };

  const resetForm = () => {
    setReceivedName('admin');
    setReceivedPassword('');
  };

  useEffect(() => {
    dispatch(fetchCheckAuthAction());
    if (statusAuthorization) {
      resetForm();
      setAuthorized(false);
    }
  }, [dispatch, statusAuthorization]);


  const onSubmit = async (data: TSubmitUser) => {
    await dispatch(fetchLoginAction(data));
  };

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setEmptyField(receivedPassword.length === 0 || receivedName.length === 0);
    setAuthorized(!statusAuthorization && (receivedPassword.length !== 0 || receivedName.length !== 0));

    onSubmit({
      fullName: receivedName,
      password: receivedPassword,
    });
  };

  return (
    <form action="#" method="post" onSubmit={handleSubmitForm} className={isEmptyField || isAuthorized ? 'invalid-form' : ''}>
      <p className="entrance__invalid-text">{isEmptyField ? 'Заполните все поля' : ''}</p>
      <div className="custom-input entrance__input" data-freeze-label="parent">
        <label htmlFor="name">
          <input onChange={handleNameChange} type="text" id="name" name="name" autoComplete="off" placeholder="логин" data-freeze-label="field"/>
        </label>
      </div>
      <div className="custom-input entrance__input" data-freeze-label="parent">
        <label htmlFor="pass">
          <input onChange={handlePasswordChange} type="password" id="pass" autoComplete="off" name="pass" placeholder="пароль" data-freeze-label="field"/>
        </label>
      </div>
      <button className="btn entrance__button" type="submit"><span>войти</span>
      </button>
    </form>
  );
}
