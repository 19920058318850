import { TEmployeesName } from './types';

export const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

export const BACKEND_URL = 'http://185.46.9.183:4444';
export const REQUEST_TIMEOUT = 5000;
export const TIMEOUT_SHOW_ERROR = 2000;

export enum AppRoute {
  Main = '/main',
  App = '/app',
  Root = '/',
}

export enum NameSpace {
  User = 'USER',
  App = 'APP',
}

export const employeessNames:TEmployeesName[] = [
  {
    family: 'Анатольева С.А.',
    jobTitle: 'Ведущий эксперт',
  },
  {
    family: 'Баранов Д.Ю.',
    jobTitle: 'Начальник управления',
  },
  {
    family: 'Бодун А.А.',
    jobTitle: 'Зам. начальника управления',
  },
  {
    family: 'Капранова Е.Н.',
    jobTitle: 'Старший аналитик',
  },
  {
    family: 'Леонов Н.П.',
    jobTitle: 'Ведущий эксперт',
  },
  {
    family: 'Лепехин С.П.',
    jobTitle: 'Ведущий эксперт',
  },
  {
    family: 'Сафоненкова О.А.',
    jobTitle: 'Зам. начальника управления',
  },
  {
    family: 'Свешникова Л.А.',
    jobTitle: 'Зам. начальника управления',
  },
];
