import {employeessNames} from '../../const';
import {useAppDispatch} from '../../hooks/useAppDispatch';
import {changeFamily} from '../../store/holidays-process/holidays-process';

import {useRef} from 'react';

export default function SortEmployee(): JSX.Element {
  const familyRef = useRef<HTMLSpanElement | null>(null);
  const dispatch = useAppDispatch();

  const handleChangeFamily = () => {
    if (familyRef.current?.textContent) {
      dispatch(changeFamily(familyRef.current.textContent));
    }
  };

  return (
    <div className="custom-select control__select" data-select data-insert="true"><span className="custom-select__placeholder"><span className="custom-select__placeholder-desktop">выбор сотрудника</span><span className="custom-select__placeholder-mobile">сотрудники</span></span>
      <button className="custom-select__button" type="button" aria-label="Выберите одну из опций"><span ref={familyRef} className="custom-select__text" onChange={handleChangeFamily}></span><span className="custom-select__icon"></span></button>
      <ul className="custom-select__list" role="listbox">
        {employeessNames ? employeessNames.map((employeessName, index) =>
          <li key={employeessName.family} onClick={handleChangeFamily} className="custom-select__item custom-select__item--mod" tabIndex={0} data-select-value={index} aria-selected="false" role="option">{employeessName.family}</li>) : null}
      </ul>
    </div>
  );
}
