import {PayloadAction, Middleware} from '@reduxjs/toolkit';
import browserHistory from '../browser-history';

export const redirect: Middleware =
  (_store) =>
    (nextDispatch) => (action: PayloadAction<string>) => {
      if (action.type === 'app/redirectToRoute') {
        browserHistory.push(action.payload);
      }

      return nextDispatch(action);
    };
